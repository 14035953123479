/* eslint-disable react/no-this-in-sfc */
// ^^state defined in StudentCommon class

import React from 'react';
import { client as apollo } from 'cccisd-apollo';
import Loader from 'cccisd-loader';
import Confetti from './confetti.js';
import style from '../StudentCommon/style.css';
import studentQuery from './studentQuery.graphql';
import { DeploymentPlayer, ProgressBar } from 'cccisd-laravel-assignment';
import StudentCommon from '../StudentCommon';

export default class StudentAssignment extends StudentCommon {
    getStudentInfo = async () => {
        let result = await apollo.query({
            query: studentQuery,
            fetchPolicy: 'network-only',
        });

        let student = result.data.roles.learner;
        if (!student) {
            this.setState({ loading: false, error: true });
            return;
        }

        // If no plan selected at Class level, check for District defaults
        let session;
        if (!student?.parentGroup?.class?.selectedAssignmentPlan?.assignmentPlanId) {
            const grade = student.fields.grade;
            const defaultPlanId = (student?.ancestorGroups?.groupingUnit?.group?.settings?.defaultAssignmentPlans ||
                {})['plan_' + grade];

            const availablePlans = student?.ancestorGroups?.groupingUnit?.createdAssignmentPlanList || [];
            const planDef =
                defaultPlanId &&
                availablePlans.find(
                    plan => plan?.role === 'learner' && plan?.assignmentPlanId === parseInt(defaultPlanId, 10)
                );

            session = planDef?.currentSession?.sessionId ? planDef.currentSession : '';
        } else {
            const currentSession = student?.parentGroup?.class?.selectedAssignmentPlan?.currentSession || '';
            session = currentSession?.sessionId ? currentSession : '';
        }

        const assessmentHeader = session?.deployment?.assignment?.settings?.selwebHeader || '';
        const pawnId = student.pawn.pawnId;
        const peers = student.parentGroup.class.childRoles.learnerList;
        const timepoint = session?.deployment?.timepoint;
        const pnLocks = JSON.parse(student.parentGroup.class.fields.pn_locks) || [];
        const lockEntry = pnLocks.find(tp => tp.timepoint === timepoint) || { locked: [] };

        this.setPeers(peers, pawnId, lockEntry.locked);
        this.setState({ loading: false, student, session, assessmentHeader });
    };

    setPeers = (peers, me, locked) => {
        const allpeers = peers
            .filter(
                student =>
                    student.pawn.pawnId !== me && student.user.fullName !== null && student.user.fullName.trim() !== ''
            )
            .map(student => {
                return {
                    pawn_id: student.pawn.pawnId,
                    name: student.user.fullName,
                    student_image: student.fields.pn_image_url,
                    student_audio: student.fields.pn_audio_url,
                };
            });

        const lockedPeers = allpeers.filter(student => locked.includes(student.pawn_id));

        if (lockedPeers.length > 1) {
            // Filter out anyone added after nominations began
            window.peers = lockedPeers;
        } else {
            // We seem to be first, no one is locked yet
            window.peers = allpeers;
        }
    };

    onComplete = language => {
        if (this.props.onComplete) {
            this.props.onComplete();
        }
        this.setState({ complete: true, language });
    };

    showAssignment = () => {
        var { student, session } = this.state;
        var mediator = {
            mustTakeInOrder: false,
        };
        return (
            <>
                {this.showSelHeader()}
                <span
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        pointerEvents: 'none',
                        position: 'relative',
                        top: '-3.43em',
                        width: '100%',
                        zIndex: 10000,
                    }}
                >
                    <ProgressBar />
                </span>
                <DeploymentPlayer
                    deploymentId={session.deployment.deploymentId}
                    pawnId={student.pawn.pawnId}
                    pawnHash={student.pawn.pawnHash}
                    assignmentOptions={session.settings.options}
                    mediatorProps={mediator}
                    disableLayout
                    onComplete={this.onComplete}
                />
            </>
        );
    };

    showComplete = () => {
        var { language } = this.state;
        if (language === null) {
            language = 'en';
        }

        const doneText = {
            en: "You're done!",
            es: '¡Has completado todo!',
        };

        const logoutText = {
            en: 'Log out',
            es: 'Cerrar sesión',
        };

        return (
            <div className={style.wrapper}>
                {this.showSelHeader()}
                <Confetti positionOverride="absolute" />
                <div className={style.question}>{doneText[language]}</div>
                <div className={style.buttons}>
                    <button
                        className={'btn btn-lg btn-success ' + style.logout}
                        type="button"
                        onClick={() => {
                            window.location = window.cccisd.boilerplate.route('api.nexus.logout');
                        }}
                    >
                        {logoutText[language]}
                    </button>
                </div>
            </div>
        );
    };

    render() {
        const { loading, error, complete, session } = this.state;

        if (loading) {
            return <Loader loading />;
        }

        if (error) {
            return this.showError();
        }

        if (!session || !session.deployment.deploymentId) {
            return this.showNoSession();
        }

        if (complete) {
            return this.showComplete();
        }

        return this.showAssignment();
    }
}
