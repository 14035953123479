import React from 'react';
import PropTypes from 'prop-types';
import $ from 'jquery';
import style from './confetti.css';

export default class Confetti extends React.Component {
    static propTypes = {
        positionOverride: PropTypes.string,
    };

    static defaultProps = {
        positionOverride: 'fixed',
    };

    componentDidMount() {
        this.makeConfetti();
    }

    makeConfetti = () => {
        // globals
        var canvas;
        var ctx;
        var W;
        var H;
        var mp = 150; // max particles
        var particles = [];
        var angle = 0;
        var tiltAngle = 0; // eslint-disable-line
        var confettiActive = true;
        var animationComplete = true;
        var reactivationTimerHandler; // eslint-disable-line
        var animationHandler; // eslint-disable-line

        // objects
        var particleColors = {
            colorOptions: [
                'DodgerBlue',
                'OliveDrab',
                'Gold',
                'pink',
                'SlateBlue',
                'lightblue',
                'Violet',
                'PaleGreen',
                'SteelBlue',
                'SandyBrown',
                'Chocolate',
                'Crimson',
            ],
            colorIndex: 0,
            colorIncrementer: 0,
            colorThreshold: 10,
            getColor() {
                if (this.colorIncrementer >= 10) {
                    this.colorIncrementer = 0;
                    this.colorIndex++;
                    if (this.colorIndex >= this.colorOptions.length) {
                        this.colorIndex = 0;
                    }
                }
                this.colorIncrementer++;
                return this.colorOptions[this.colorIndex];
            },
        };

        function ConfettiParticle(color) {
            this.x = Math.random() * W; // x-coordinate
            this.y = Math.random() * H - H; // y-coordinate
            this.r = RandomFromTo(10, 30); // radius;
            this.d = Math.random() * mp + 10; // density;
            this.color = color;
            this.tilt = Math.floor(Math.random() * 10) - 10;
            this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
            this.tiltAngle = 0;

            // eslint-disable-next-line
            this.draw = function () {
                ctx.beginPath();
                ctx.lineWidth = this.r / 2;
                ctx.strokeStyle = this.color;
                ctx.moveTo(this.x + this.tilt + this.r / 4, this.y);
                ctx.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 4);
                return ctx.stroke();
            };
        }

        $(document).ready(() => {
            SetGlobals();
            InitializeConfetti();

            $(window).resize(() => {
                W = window.innerWidth;
                H = window.innerHeight;
                canvas.width = W;
                canvas.height = H;
            });
        });

        function SetGlobals() {
            canvas = document.getElementById('confetti');
            ctx = canvas.getContext('2d');
            W = window.innerWidth;
            H = window.innerHeight;
            canvas.width = W;
            canvas.height = H;
        }

        function InitializeConfetti() {
            particles = [];
            animationComplete = false;
            for (var i = 0; i < mp; i++) {
                var particleColor = particleColors.getColor();
                particles.push(new ConfettiParticle(particleColor));
            }
            StartConfetti();
        }

        function Draw() {
            ctx.clearRect(0, 0, W, H);
            var results = [];
            for (var i = 0; i < mp; i++) {
                // eslint-disable-next-line
                (function (j) {
                    results.push(particles[j].draw());
                })(i);
            }
            Update();

            return results;
        }

        function RandomFromTo(from, to) {
            return Math.floor(Math.random() * (to - from + 1) + from);
        }

        function Update() {
            var remainingFlakes = 0;
            var particle;
            angle += 0.01;
            tiltAngle += 0.1;

            for (var i = 0; i < mp; i++) {
                particle = particles[i];
                if (animationComplete) return;

                if (!confettiActive && particle.y < -15) {
                    particle.y = H + 100;
                    continue;
                }

                stepParticle(particle, i);

                if (particle.y <= H) {
                    remainingFlakes++;
                }
                CheckForReposition(particle, i);
            }

            if (remainingFlakes === 0) {
                StopConfetti();
            }
        }

        function CheckForReposition(particle, index) {
            if ((particle.x > W + 20 || particle.x < -20 || particle.y > H) && confettiActive) {
                if (index % 5 > 0 || index % 2 === 0) {
                    // 66.67% of the flakes
                    repositionParticle(particle, Math.random() * W, -10, Math.floor(Math.random() * 10) - 20);
                } else if (Math.sin(angle) > 0) {
                    // Enter from the left
                    repositionParticle(particle, -20, Math.random() * H, Math.floor(Math.random() * 10) - 20);
                } else {
                    // Enter from the right
                    repositionParticle(particle, W + 20, Math.random() * H, Math.floor(Math.random() * 10) - 20);
                }
            }
        }

        function stepParticle(particle, particleIndex) {
            particle.tiltAngle += particle.tiltAngleIncremental;
            particle.y += (Math.cos(angle + particle.d) + 3 + particle.r / 2) / 2;
            particle.x += Math.sin(angle);
            particle.tilt = Math.sin(particle.tiltAngle - particleIndex / 3) * 15;
        }

        function repositionParticle(particle, xCoordinate, yCoordinate, tilt) {
            particle.x = xCoordinate;
            particle.y = yCoordinate;
            particle.tilt = tilt;
        }

        function StartConfetti() {
            W = window.innerWidth;
            H = window.innerHeight;
            canvas.width = W;
            canvas.height = H;
            (function animloop() {
                if (animationComplete) return null;
                animationHandler = window.requestAnimationFrame(animloop);
                return Draw();
            })();
        }

        /*
        function ClearTimers() {
            clearTimeout(reactivationTimerHandler);
            clearTimeout(animationHandler);
        }
        */

        function StopConfetti() {
            animationComplete = true;
            if (ctx === undefined) return;
            ctx.clearRect(0, 0, W, H);
        }
    };

    render() {
        return <canvas className={style.fullscreen} style={{ position: this.props.positionOverride }} id="confetti" />;
    }
}
